import { paths } from "../../utils";
import { reportError } from "@lib/utils/reportError";
import { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { useSessionContext, useSupabaseClient } from "@supabase/auth-helpers-react";

export function useAuth() {
  const { session, isLoading, error } = useSessionContext();
  const supabaseClient = useSupabaseClient();
  const router = useRouter();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  if (error) {
    reportError(error);
  }

  const signOut = useCallback(async (skipRedirect: boolean = false) => {
    if (redirecting) return;

    setRedirecting(true);
    await supabaseClient.auth.signOut()
    if (!skipRedirect) router.push(paths.index)

    setRedirecting(false);
  }, [redirecting, supabaseClient, router]);

  const redirectToSignIn = useCallback(() => {
    if (redirecting) return;

    console.warn("Redirecting!");

    setRedirecting(true);

    // TODO: Add in ?redirect=window.location etc
    window.location.assign(paths.signIn);

    setRedirecting(false);
  }, [redirecting]);

  return { user: session?.user, session, loading: isLoading, signOut, redirectToSignIn };
}
