import { AxiosProgressEvent } from "axios";
import { BareFetcher, Key, SWRResponse } from "swr";
import {
  DevSongs,
  EnabledFeatureFlags,
  IsSuperuser,
  Playlist,
  PlaylistRow,
  Profile,
  PublicProfile,
  Song,
  SongWithPlaylists,
} from "../types";
import { NotAuthenticatedError, NotFoundError, SupabasePostgrestError } from "../errors";
import { SongsFilters } from "../components/library/SongsFilter";
import { User } from "@supabase/supabase-js";
import { devSongsSchema, enabledFeatureFlagsSchema, isSuperuserSchema } from "../schemas";
import { log } from "@lib/utils/log";
import { paths } from "../utils/paths";
import { reportError } from "@lib/utils/reportError";
import { request, useSupabaseAPIClient } from "./helper";
import { useAuth } from "../hooks/utils/useAuth";
import { useRouter } from "next/router";
import useSWR from "swr";

const useAuthedQuery = <Data, Error = any>(
  key: Key,
  fetcher: (user: User) => BareFetcher<Data>,
  throwOnUnauthed: boolean = true
): SWRResponse<Data | undefined, Error> => {
  const { user, loading } = useAuth();

  const fetcherFn = async () => {
    if (!user) {
      if (!throwOnUnauthed) return undefined;

      log("Not authed", key);
      throw new NotAuthenticatedError("User not authenticated");
    }

    return fetcher(user)();
  };

  return useQuery<Data | undefined, Error>(!loading ? key : null, fetcherFn);
};

const useQuery = <Data, Error = any>(key: Key, fetcher: BareFetcher<Data>): SWRResponse<Data | undefined, Error> => {
  const router = useRouter();
  const { redirectToSignIn } = useAuth();

  const fetcherFn: BareFetcher<Data | undefined> = async () => {
    try {
      return await fetcher();
    } catch (error) {
      // TODO: At the moment we don't catch 401s – they instead go to 500 page
      if (error instanceof NotFoundError) {
        reportError(error);
        router.push(paths.error(404));
        return undefined;
      } else if (error instanceof NotAuthenticatedError) {
        redirectToSignIn();
        return undefined;
      } else {
        throw error;
      }
    }
  };

  return useSWR<Data | undefined, Error>(key, fetcherFn, undefined);
};

export const useProfile = () => {
  const client = useSupabaseAPIClient();

  const fetch = (user: User) => async () => {
    const { data, error, status } = await client
      .from("profiles")
      .select()
      .eq("id", user.id)
      .eq("status", "active");

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    const profile = data[0];
    if (profile === undefined) {
      throw new NotFoundError("Profile not found");
    }

    return profile;
  };

  return useAuthedQuery<Profile>(`/profile/current`, fetch);
};

// TODO: Make this more secure
export const usePublicProfile = (id: string | undefined) => {
  const client = useSupabaseAPIClient();

  const fetch = async () => {
    const { data, error, status } = await client.from("public_profiles").select().eq("id", id || "");

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    const profile = data[0];
    if (profile === undefined) {
      throw new NotFoundError("Profile not found");
    }

    return profile;
  };

  return useQuery<PublicProfile>(!!id ? `/public_profile/${id}` : null, fetch);
};

export const usePublicProfiles = () => {
  const client = useSupabaseAPIClient();

  const fetch = async () => {
    const { data, error, status } = await client.from("public_profiles").select();

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    if (data === undefined) {
      throw new NotFoundError("Profile not found");
    }

    return data;
  };

  return useQuery<PublicProfile[]>(`/public_profiles/`, fetch);
};

export const useSong = (id: string) => {
  const client = useSupabaseAPIClient({ allowSecret: true });

  const fetch = async (): Promise<Song> => {
    const { data, error, status } = await client
      .from("songs")
      .select("*, channels (*, regions (*))")
      .eq("id", id)
      .eq("status", "active");

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    const song = data[0]

    if (song === undefined) {
      throw new NotFoundError("Song not found");
    }

    return {
      ...song,
      channels: song.channels.sort((a, b) => a.order - b.order)
    };
  };

  return useQuery<Song>(`/song/${id}`, fetch);
};

export const useDismissedBlockKeys = () => {
  const client = useSupabaseAPIClient({ allowSecret: false });

  const fetch = (user: User) => async (): Promise<string[]> => {
    const { data, error, status } = await client
      .from("dismissed_blocks")
      .select("key")
      .eq("userId", user.id);

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error) {
      throw new SupabasePostgrestError(error);
    }

    const dismissedKeys = data.map(db => db.key)

    log("Dismissed blocks:", dismissedKeys)

    return dismissedKeys
  };

  return useAuthedQuery<string[]>(`/dismissed_block_keys`, fetch, false);
};

export const useSongs = () => {
  const client = useSupabaseAPIClient({ allowSecret: true });

  const fetch = (user: User) => async (): Promise<SongWithPlaylists[]> => {
    const { data, error, status } = await client
      .from("songs")
      .select(
        `
          *,
          channels (
            *,
            regions (*)
          ),
          playlists_songs (
            playlist:playlists (
              *
            )
          )
        `
      )
      .eq("userId", user.id)
      .eq("status", "active")
      .order("updatedAt", { ascending: false });

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error) {
      throw new SupabasePostgrestError(error);
    }

    const songs = data.map((songData) => {
      const { playlists_songs, ...rest } = songData;

      return {
        ...rest,
        channels: songData.channels.sort((a, b) => a.order - b.order),
        playlists: playlists_songs.map((ps) => ps.playlist) as PlaylistRow[],
      };
    });

    return songs;
  };

  return useAuthedQuery<SongWithPlaylists[]>(`/songs`, fetch);
};

export const useAllSongs = (filters: SongsFilters, page: number, pageSize: number) => {
  const client = useSupabaseAPIClient();

  const fetch = async () => {
    // page is zero-based, both values are inclusive
    const start = (page - 1) * pageSize;
    const end = (page - 1) * pageSize + pageSize - 1;
    console.log("start", start, "end", end)

    var query = client
      .from("songs")
      .select(
        `
        *,
        channels (
          *,
          regions (*)
        ),
        playlists_songs (
          playlist:playlists (
            *
          )
        )
      `
      )
      .eq("status", "active")
      .range(start, end)

    if (filters.userId) {
      query = query.eq("userId", filters.userId)
    }

    const { data, error, status } = await query.order("updatedAt", { ascending: false });

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error) {
      throw new SupabasePostgrestError(error);
    }

    const songs = data.map((songData) => {
      const { playlists_songs, ...rest } = songData;

      return {
        ...rest,
        playlists: playlists_songs.map((ps) => ps.playlist) as PlaylistRow[],
      };
    });

    return songs;
  };

  return useQuery<SongWithPlaylists[]>({ key: `/songs/all`, filters, page, pageSize }, fetch);
};

export const usePlaylists = () => {
  const client = useSupabaseAPIClient();

  const fetch = (user: User) => async () => {
    const { data, error, status } = await client
      .from("playlists")
      .select()
      .eq("userId", user.id)
      .eq("status", "active")
      .order("updatedAt", { ascending: false });

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    return data;
  };

  return useAuthedQuery<PlaylistRow[]>("/playlists", fetch);
};

export const useSongViewCount = (songId: string) => {
  const client = useSupabaseAPIClient();

  const fetch = async () => {
    const { data, error, status } = await client
      .from("song_view_counts")
      .select(`*`)
      .eq("songId", songId);

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    if (data.length === 0) {
      return 0;
    }

    if (data.length > 1) {
      throw new Error(`Returned too many view count entries (${data.length})`)
    }

    return data[0].view_count;
  };

  return useQuery<number>(`/song/${songId}/viewCount`, fetch);
}

export const usePlaylist = (id: string | undefined) => {
  const client = useSupabaseAPIClient({ allowSecret: true });

  const fetch = async (): Promise<Playlist> => {
    const { data, error, status } = await client
      .from("playlists")
      .select(
        `
          *,
          playlists_songs (
            order,
            song:songs (
              *,
              channels (*, regions (*))
            )
          )
        `
      )
      .eq("id", id || "")
      .eq("status", "active");

    if (status === 401) {
      throw new NotAuthenticatedError("User not authenticated");
    }

    if (error || !data) {
      throw new SupabasePostgrestError(error);
    }

    const playlist = data[0];

    if (playlist === undefined) {
      throw new NotFoundError("Playlist not found");
    }

    const { playlists_songs, ...rest } = playlist;

    return {
      ...rest,
      songs: playlists_songs
        .sort((a, b) => a.order - b.order)
        .map((ps) => ps.song as Song)
        // Necessary until we can filter by joined tables https://github.com/supabase/postgrest-js/issues/197
        .filter((s) => s.status === "active"),
    };
  };
  return useQuery<Playlist>(!!id ? `/playlist/${id}` : null, fetch);
};

export const getBlob = async (url: string, onProgress?: (e: AxiosProgressEvent) => void): Promise<Blob> => {
  const [data, contentType] = await request<string>({
    path: url,
    method: "get",
    responseType: "blob",
    onDownloadProgress: onProgress,
  });

  return new Blob([data], { type: contentType || undefined });
};

export const isSuperuserQuery = async (): Promise<IsSuperuser> => {
  const [data] = await request<IsSuperuser>({
    path: "/api/isSuperuser",
    method: "get",
    schema: isSuperuserSchema,
  });

  return data;
};

export const enabledFeatureFlagsQuery = async (userId: string, referenceUserId?: string): Promise<EnabledFeatureFlags> => {
  const [data] = await request<EnabledFeatureFlags>({
    path: "/api/enabledFeatureFlags",
    method: "get",
    schema: enabledFeatureFlagsSchema,
    params: { userId, referenceUserId }
  });

  return data;
};

export const getDevSongs = async (): Promise<DevSongs> => {
  const [data] = await request<DevSongs>({
    path: "/api/devSongs",
    method: "get",
    schema: devSongsSchema,
  });

  return data;
};
