import { isSuperuserQuery } from "../api/queries";
import { useAuth } from "../hooks/utils/useAuth";
import React, { ReactNode, createContext, useCallback, useEffect, useState } from "react";

type ContextValue = {
  loading: boolean;
  isSuperuser: boolean | undefined;
  superuserModeEnabled: boolean;
  toggleSuperuserMode: () => void;
};

export const SuperuserContext = createContext<ContextValue>({
  loading: false,
  isSuperuser: undefined,
  superuserModeEnabled: false,
  toggleSuperuserMode: () => { },
});

type Props = {
  children: ReactNode;
};

export function SuperuserProvider({ children }: Props) {
  const { user } = useAuth();
  const [isSuperuser, setIsSuperuser] = useState<boolean | undefined>(undefined);
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const result = await isSuperuserQuery();

      if (result.result === "success") {
        setIsSuperuser(result.isSuperuser);
      } else {
        reportError(new Error(result.message));
      }
    };

    if (user && isSuperuser === undefined) {
      loadData();
    }
  }, [isSuperuser, user]);

  const toggleSuperuserMode = useCallback(() => {
    if (isSuperuser === undefined) {
      throw new Error("Superuser not yet ready");
    }
    if (!isSuperuser) {
      throw new Error("User is not a superuser");
    }

    setEnabled((prev) => !prev);
  }, [isSuperuser]);

  return (
    <SuperuserContext.Provider
      value={{
        loading: isSuperuser === undefined,
        isSuperuser,
        superuserModeEnabled: enabled,
        toggleSuperuserMode,
      }}
    >
      {children}
    </SuperuserContext.Provider>
  );
}
