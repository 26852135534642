import { reportError } from "@lib/utils/reportError";
import localForage from "localforage";

const STORE_NAME = "trackshare-cache";

export class Storage {
  private store: LocalForage;

  constructor() {
    this.store = localForage.createInstance({
      driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
      name: STORE_NAME,
    })
  }

  async getItem<T>(key: string, deprecatedKey?: string): Promise<T | null> {
    try {
      const value = await this.store.getItem<T>(key);

      if (value !== null) {
        return value;
      } else if (value === null && deprecatedKey !== undefined) {
        const valueToMigrate = await this.store.getItem<T>(deprecatedKey);
        if (valueToMigrate !== null) {
          await this.store.setItem(key, valueToMigrate)
          await this.store.removeItem(deprecatedKey)

          return valueToMigrate
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      reportError(error);
      return null;
    }
  }

  async setItem<T>(key: string, item: T) {
    try {
      return await this.store.setItem<T>(key, item);
    } catch (error) {
      reportError(error);
    }
  }

  async removeItem(key: string) {
    try {
      return await this.store.removeItem(key);
    } catch (error) {
      reportError(error);
    }
  }

  async clear() {
    try {
      return await this.store.clear();
    } catch (error) {
      reportError(error);
    }
  }
}
