import { shortenUUID } from "./uuids";

export const paths = {
  song: (id: string, { secret, playlistId }: { secret?: string, playlistId?: string } = {}) => {
    const base = playlistId ? `/playlist/${shortenUUID(playlistId)}/song/${shortenUUID(id)}` : `/song/${shortenUUID(id)}`

    if (secret) {
      return `${base}?s=${secret}`
    }

    return base
  },
  playlist: (id: string, { secret }: { secret?: string } = {}) =>
    secret ? `/playlist/${shortenUUID(id)}?s=${secret}` : `/playlist/${shortenUUID(id)}`,
  all: `/all`,
  upload: `/upload`,
  signIn: `/signin`,
  recover: `/signin/recover`,
  register: `/register`,
  index: "/",
  demo: "/demo",
  support: "/support",
  account: "/account",
  donate: "/donate",
  help: "/help",
  admin: "/admin",
  dev: "/dev",
  error: (code: number) => `/${code}`,
};

export const getAbsolutePath = (path: string) => {
  const origin = window.location.origin;

  return `${origin}${path}`
}
