import { Component, ReactNode } from "react";
import { reportError } from "@lib/utils/reportError";

type ErrorBoundaryProps = {
  onError: ((_: Error | undefined) => ReactNode | null) | ReactNode;
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | undefined;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    reportError(error);
  }

  render() {
    if (this.state.hasError && this.props.onError) {
      return typeof this.props.onError === "function" ? this.props.onError(this.state.error) : this.props.onError;
    }
    return this.props.children;
  }
}
