import { TitleContext } from "../../providers/TitleProvider";
import { useContext } from "react";

export function useTitle() {
  const { title, setTitle } = useContext(TitleContext);

  const calculatedTitle = title ? `${title} | trackshare` : "trackshare";

  return { title: calculatedTitle, setTitle };
}
