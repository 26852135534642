import { AmplitudeProvider } from "../src/providers/AmplitudeProvider";
import { FeatureFlagsProvider } from "../src/providers/FeatureFlagsProvider";
import { SecretClientProvider } from "../src/providers/SecretClientProvider";
import { StorageProvider } from "../src/providers/StorageProvider";
import { SuperuserProvider } from "../src/providers/SuperuserProvider";
import { TitleProvider } from "../src/providers/TitleProvider";
import { AppProps, NextWebVitalsMetric } from "next/app";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { ErrorBoundary } from "../src/components/common/ErrorBoundary";
import { SWRConfig } from "swr";
import { Session, SessionContextProvider } from '@supabase/auth-helpers-react'
import { config } from "@lib/utils/config";
import { paths } from "../src/utils/paths";
import { supabase } from "../src/utils/supabase";
import { useRouter } from "next/router";
import { useTitle } from "../src/hooks/utils/useTitle";
import { useSWRCache } from "../src/hooks/utils/useSWRCache";
import Head from "next/head";
import PlausibleProvider from "next-plausible";
import React from "react";

interface ComposedProvidersProps {
  children: React.ReactNode;
  initialSession: Session
}

const SWRCache = ({ children }) => {
  const { ready } = useSWRCache()

  if (!ready) return null;

  return children;
}

const ComposedProviders: React.FunctionComponent<ComposedProvidersProps> = ({ children, initialSession }) => {
  // Store in state, or const?
  //   const [supabaseClient] = useState(() => createBrowserSupabaseClient())

  return (
    <ErrorBoundary
      onError={(error: Error) => {
        if (config.environment !== "development") {
          window.location.assign("/500");
        } else {
          console.warn(
            `Uncaught error reached ErrorBoundary. This would be redirect users to /500 in production.`,
            error
          );
        }
        return null;
      }}
    >
      <SWRConfig value={{ revalidateOnFocus: false, revalidateOnReconnect: false }}>
        <StorageProvider>
          <SWRCache>
            <SessionContextProvider
              supabaseClient={supabase}
              initialSession={initialSession}
            >
              <SecretClientProvider>
                <AmplitudeProvider>
                  <FeatureFlagsProvider>
                    <SuperuserProvider>
                      <TitleProvider>
                        <PlausibleProvider enabled={config.environment !== "development"} domain={config.domain}>
                          <ChakraProvider>
                            {children}
                          </ChakraProvider>
                        </PlausibleProvider>
                      </TitleProvider>
                    </SuperuserProvider>
                  </FeatureFlagsProvider>
                </AmplitudeProvider>
              </SecretClientProvider>
            </SessionContextProvider>
          </SWRCache>
        </StorageProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

const Meta: React.FunctionComponent = () => {
  const { title } = useTitle();

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:image" content={`${config.baseUrl}/static/img/social-preview.png`} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="trackshare" />
      <meta name="description" content="A simple, web-based multitrack player for sharing rehearsal tracks" />
      <meta property="og:description" content="A simple, web-based multitrack player for sharing rehearsal tracks" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export const Root: React.FunctionComponent<AppProps<{
  initialSession: Session
}>> = ({ Component, pageProps }) => {
  const router = useRouter();

  React.useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, _session) => {
      if (event === "PASSWORD_RECOVERY" && router.pathname !== paths.recover) router.push(paths.recover);
    })

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [router])

  return (
    <ComposedProviders
      initialSession={pageProps.initialSession}
    >
      <ColorModeScript />
      <Meta />
      {/* <CssBaseline /> */}
      <Component {...pageProps} />
    </ComposedProviders>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const url = process.env.NEXT_PUBLIC_AXIOM_INGEST_ENDPOINT;

  if (!url) {
    return;
  }

  const body = JSON.stringify({
    route: window.__NEXT_DATA__.page,
    ...metric,
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: "POST", keepalive: true });
  }
}

export default Root;
